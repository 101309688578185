body {
  background: #888
}

input, select, textarea {
  border: 1px solid #777
}

input:hover, select:hover, textarea:hover {
  border: 1px solid #8dbb00
}

input[type=submit], #paiementchoix .button, .button {
  background: #8dbb00;
  color: #000
}

h2, .h2 {
  color: #8dbb00;
  a, a:hover{
    color:inherit !important;
  }
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  background-color: #8dbb00;
  border-color: #8dbb00
}

.pagination > li > a, .pagination > li > span {
  color: #8dbb00
}

.pagination.pull-right li.active a {
  color: #fff !important
}

#wrapper {
  background: url(../images/imgd387series/bg_body.png) repeat-y top left
}

#wrapper_inner {
  background: #FFF
}

#header {
  background: #FFF
}

#header_menu a {
  color: #000
}

#header_mask {
  background: url(../images/imgd387series/bg_mask.png) no-repeat 0 0
}

#header_mask2 {
  background: url(../images/imgd387series/bg_mask_2.png) no-repeat 0 0
}

#header #contact {
  background: gray url(../images/imgd387series/bg_contact.png) no-repeat 65px 7px
}

#header #contact a {
  color: #FFF
}

nav#nav-principal {
  color: #777;
  background: #fff;
  border: 3px solid #8dbb00
}

nav#nav-principal a {
  color: #777
}

#content {
  background: #FFF
}

#slider {
  background: #8dbb00 url(../images/imgd387series/bg_slider.png) repeat top left
}

#slider_inner span span {
  background: #8dbb00;
  color: #FFF
}

#slider .bx-prev {
  background: gray;
  color: #FFF
}

#slider .bx-next {
  background: gray;
  color: #FFF
}

.message_lo {
  border: 3px solid #8dbb00
}

.message_lo .note {
  background: #eee
}

#content .addmsglo {
  background: #8dbb00;
  color: #000
}

#footer {
  border-top: 3px solid #8dbb00
}

#footer a {
  color: #000
}

#content .galerie {
  border: 3px solid #8dbb00;
  background: #FFF
}

#content .galerie a.addcommentaires {
  color: #000
}

#content .produits {
  border: 3px solid #8dbb00;
  background: #fff
}

.addbasket {
  color: #000;
  background: #8dbb00
}

.addbasket:hover {
  background: #8dbb00 url(../images/imgd387series/bg_degrade.png) repeat-x top left
}

#content .remise {
  background: red;
  color: #FFF;
  border: 3px solid #fff
}

#content .produits:hover .remise {
  border: 3px solid #8dbb00
}

#content .produits:hover a.addbasket {
  background: #777;
  color: #FFF
}

#panier {
  border: 3px solid #8dbb00
}

input[type=submit]:hover, .button:hover, #content .addmsglo:hover, #paiementchoix .button:hover {
  background: #8dbb00;
  color: #FFF
}

#header_menu a span.sous_navigation_button_p, nav#nav-principal>ul li a span.sous_navigation_button_p {
  border:4px solid #8dbb00;
}

#header_menu a:hover, nav#nav-principal li:hover > a, nav#nav-principal li.actif > a {
  color: #8dbb00
}

#slider_inner span span a, #content .galerie:hover a.addcommentaires {
  color: #FFF
}

#content .galerie:hover, #content .produits:hover {
  background: #8dbb00
}

.produit_etiquette:after {
  border-color: rgb(141, 187, 0) rgba(65, 129, 217, 0) rgba(65, 129, 217, 0)
}

.produit_etiquette {
  background: rgb(141, 187, 0)
}

.produit_etiquette2 {
  background: rgb(141, 187, 0)
}

.produit_etiquette2:after {
  border-color: rgba(0, 0, 0, 0) rgb(117, 150, 9) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0)
}

@media screen and (max-width:768px){
  nav#nav-principal{
    border-left-color:rgb(141, 187, 0) !important;
  }
}

/** Flux Panier **/

.button.button-secondary {
  background: orange;

  &:hover {
    background: #444;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
  background: #8dbb00;
  border-color: #8dbb00;

  a {
    color: #fff;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
  border-color: #8dbb00;
  color: #8dbb00;

  a, span {
    color: #8dbb00;
  }
}